import { useLayoutEffect } from "react";

export default function useRedirect() {
  function getUrlParam(param) {
    var parameters = window.location.search.substring(1);
    var sURLVariables = parameters.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] == param) {
        return sParameterName[1]
      }
    }
  }
  // var hasData = getUrlParam("data");
  var hasData = window.location.search.replace('?data=','');

  // var androidIntentUrl =
  //   "intent://bestowpayments.com/" +
  //   window.location.search +
  //   `#Intent;scheme=http;package=com.bestowpayments.app;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails?id=com.bestowpayments.app&referrer=${hasData}` +
  //   window.location.search +
  //   ";end";
  var androidIntentUrl =
    "intent://bestowpayments.com/" +
    window.location.search +
    `#Intent;scheme=http;package=com.bestowpayments.app;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails?id=com.bestowpayments.app&referrer=${hasData}` +
    ";end";
  
  var isMobile = {
    Windows: function () {
      return /IEMobile|Windows Phone|Lumia/i.test(navigator.userAgent);
    },
    Android: function () {
      return /Android/i.test(navigator.userAgent);
    },
    iPhone: function () {
      return /iPhone/i.test(navigator.userAgent);
    },
  };
  // Windows return both android and iPhone in its identity, need to check first.

  useLayoutEffect(() => {
    if (!isMobile.Windows() && hasData) {
      if (isMobile.Android()) {
        window.location = androidIntentUrl;
      }
      if (isMobile.iPhone()) {
        // window.location = appleStoreUrl;
      }
    }
  }, []);
}
